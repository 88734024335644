import React, { useState, useEffect } from 'react'
import '../css/ProductPage.css'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import SizeGuide from '../assets/sizechart.jpeg'
import Drawer from "react-bottom-drawer";
import FsLightbox from "fslightbox-react";
import client from '../utils/shopify';
import { Add, Close } from '@mui/icons-material';
import { Modal } from '@mui/material';
import Collections from './Collections';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import PAL from '../components/PAL';
import { TimelineLite } from 'gsap/gsap-core';
gsap.registerPlugin(ScrollTrigger);


function ProductPage({ props }) {
  let { id } = useParams();
  const [isSize, setIsSize] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [imgZoom, setImgZoom] = useState(false);
  const [imgAddr, setImgAddr] = useState(0);
  const [isLeftVisible, setIsLeftVisible] = useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  const closeDrawer = React.useCallback(() => setIsVisible(false), []);
  const openLeftDrawer = React.useCallback(() => setIsLeftVisible(true), []);
  const closeRightDrawer = React.useCallback(() => setIsLeftVisible(false), []);
  const navigate = useNavigate()
  const [product, setProduct] = useState([])
  const [productImages, setProductsImages] = useState([])
  const [price, setPrice] = useState(0)
  const [material, setMaterial] = useState("")
  const [sizeguide, setSizeguide] = useState("")
  const [packaging, setPackaging] = useState("")
  const [measurement, setMeasurement] = useState("")
  const [variants, setVariants] = useState([])
  const [expanded, setExpanded] = React.useState('panel1');
  const [seeMeasure, setSeeMeasure] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const productQuery = `
  query MyQuery($handle: String) {
    products(query: $handle, first: 1) {
      nodes {
        description
        title
        handle
        id
        images(first: 10) {
          nodes {
            originalSrc
          }
        }
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            currencyCode
            amount
          }
        }
        variants(first: 10) {
          nodes {
            id
            title
          }
        }
    metafields(identifiers: [{key: "material", namespace: "custom"},{key: "size",namespace: "custom"},{key: "packaging",namespace: "custom"},{key: "measurement",namespace: "custom"}]) {
      value
      reference{
        ... on MediaImage {
          image {
            originalSrc
          }
        }
      }
    }
      }
    }
  }`

  const addToMutation = `
  mutation MyMutation($id: ID!, $merchandiseId: ID!) {
    cartLinesAdd(cartId: $id, lines: {merchandiseId: $merchandiseId}) {
      cart {
        checkoutUrl
        id
      }
    }
  }`



  const fetchCollection = async () => {
    const { data, errors } = await client.request(productQuery, {
      variables: {
        handle: id,
      },
    })
    console.log(data)
    console.log(errors)
    setProduct(data['products']['nodes'][0])
    setProductsImages(data['products']['nodes'][0]['images']['nodes'])
    setPrice(data['products']['nodes'][0]['priceRange']['minVariantPrice']['amount'])
    setVariants(data['products']['nodes'][0]['variants']['nodes'])
    setMaterial(data['products']['nodes'][0]['metafields'][0]['value'] == null ? "" : data['products']['nodes'][0]['metafields'][0]['value'])
    setSizeguide(data['products']['nodes'][0]['metafields'][1]['value'] == null ? "" : data['products']['nodes'][0]['metafields'][1]['value'])
    setPackaging(data['products']['nodes'][0]['metafields'][2]['value'] == null ? "" : data['products']['nodes'][0]['metafields'][2]['value'])
    setMeasurement(data['products']['nodes'][0]['metafields'][3]['reference']['image']['originalSrc'])
    console.log(data['products']['nodes'][0]['metafields'][3]['reference']['image']['originalSrc'])
  }

  const addToCart = async (selVariant) => {

    const { data, error } = await client.request(addToMutation, {
      variables: {
        id: localStorage.getItem('cartId'),
        merchandiseId: selVariant
      }
    })
    console.log(error)
    console.log(data)
  }


  useEffect(function () {
    fetchCollection()

  }, [id])

  const useMousePosition = () => {
    const [
      mousePosition,
      setMousePosition
    ] = React.useState({ x: null, y: null });

    React.useEffect(() => {
      const updateMousePosition = ev => {
        setMousePosition({ x: ev.clientX, y: ev.clientY });
      };

      window.addEventListener('mousemove', updateMousePosition);

      return () => {
        window.removeEventListener('mousemove', updateMousePosition);
      };
    }, []);

    return mousePosition;
  };

  const mousePosition = useMousePosition();

  useGSAP(() => {
    gsap.from('.product__detail', {
      y: 200,
      opacity: 0,
      duration: 1,

    })

  })

  useEffect(() => {
    const html = document.querySelector("html")
    if (html) {
      html.style.overflow = seeMeasure ? "hidden" : "scroll";
    }
  }, [seeMeasure])

  return (
    <div className='product__page'>
      {

        imgZoom ?
          <div onClick={() => setImgZoom(false)} style={{ position: 'fixed', top: '0', width: "100vw", height: "100vh", zIndex: 1101, background: '#E9E6E1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

            <img style={{ overflow: 'hidden', height: '200vh', top: Math.abs(mousePosition.y) * -1, position: 'fixed' }} src={productImages[imgAddr]['originalSrc']} />
          </div>
          : <></>
      }
      {
        seeMeasure
          ? <div className='seemeasure' onClick={() => setSeeMeasure(false)} >
            <img src={measurement} />
          </div>
          : <></>
      }

      <div className='product__detail'>
        <section className='product__info__left'>
          <div className='product__mob'>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
              <h1 style={{ fontSize: '2.2vh' }}>{product['title']}</h1>
              <h2 style={{ opacity: 0.8, fontSize: '2.2vh', marginTop: '10px' }}><span>INR</span> {price}</h2>
            </div>

          </div>
          <div className='addtocartM'>
            <label style={{ height: '4px' }}></label>
            <button onClick={() => openDrawer()} className='addtocartM'>ADD TO CART</button>
            <label style={{ height: '4px' }}></label>
            <div className='seemore__box'>
              <button onClick={() => openLeftDrawer()} className='seemore'>SEE MORE</button>
            </div>
          </div>
          <div className='listing'>
            <Accordion expanded={true} style={{ background: 'transparent', boxShadow: 'none', width: '100%' }}>
              <AccordionSummary
              >
                DESCRIPTION
              </AccordionSummary>
              <AccordionDetails>
                {product['description']}
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} className='accorddrop' onChange={handleChange('panel2')} >
              <AccordionSummary
                expandIcon={expanded === 'panel2' ? <Close /> : <Add />}
              >
                MATERIALS
              </AccordionSummary>
              <AccordionDetails>
                {material}
              </AccordionDetails>
            </Accordion>
          </div>
        </section>
        <section className='product__images'>
          {productImages.map((images, index) => (
            <img alt='productIMG' src={images['originalSrc']} onClick={() => { if (window.innerWidth > 1400) { setImgZoom(true); setImgAddr(index) } }} />
          ))}
        </section>
        <section className='product__info__right'>
          {
            isSize ?
              <div className='modalData'>
                <div className='variantHolder'>
                  {
                    variants.map((variant, index) => (
                      <button onClick={e => { addToCart(variants[index]['id']).then(() => closeDrawer()).then(() => navigate('/cart')); }}>{variant['title']}</button>
                    ))
                  }
                </div>
              </div>
              : <></>
          }
          {
            !isSize
              ? <button onMouseEnter={() => setHidden(false)} onMouseLeave={() => setHidden(true)} onClick={() => setIsSize(!isSize)} className={`addtocart`}>{hidden ? <label className='fade-out'>ADD TO CART</label> : <label className='fade-in'>SELECT A SIZE </label>} </button>
              : <></>
          }

          <div style={{ height: '20px' }}></div>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className='accorddrop' style={{ background: 'transparent', boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={expanded === 'panel3' ? <Close /> : <Add />}
            >
              SIZE GUIDE
            </AccordionSummary>
            <AccordionDetails>
              <button onClick={() => setSeeMeasure(true)} className='seebtn'>See measurements</button>
             {sizeguide}
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className='accorddrop' style={{ background: 'transparent', boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={expanded === 'panel4' ? <Close /> : <Add />}
            >
              PACKAGING
            </AccordionSummary>
            <AccordionDetails>
              {packaging}
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} className='accorddrop' style={{ background: 'transparent', boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={expanded === 'panel5' ? <Close /> : <Add />}
            >
              SHIPPING & RETURN
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ display: 'flex'}}>
                <div>
                  <p>/</p>
                  <p>/ </p>
                </div>
                <div>
                  <p>&nbsp; 3-7 business days .</p>
                  <p>&nbsp;This time is subject to change due to the nature of production and heavy order volumes.</p>
                </div>
                <p style={{position: 'absolute',bottom: '4px', right: '4px'}}>&nbsp; <Link to={'/faq'} style={{color: 'black', textDecoration: 'none'}}>Policy*</Link></p>
              </div>

            </AccordionDetails>
          </Accordion>

        </section>
      </div>
      <div className='drawer__section'>
        <Drawer
          duration={250}
          hideScrollbars={true}
          onClose={closeRightDrawer}
          isVisible={isLeftVisible}
        >
          <Accordion expanded={true} className='accorddrop' style={{ background: 'transparent', boxShadow: 'none', width: '100%' }}>
            <AccordionSummary
            >
              DESCRIPTION
            </AccordionSummary>
            <AccordionDetails>
              {product['description']}
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className='accorddrop' style={{ background: 'transparent', boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={<Add />}
            >
              MATERIALS
            </AccordionSummary>
            <AccordionDetails>
              {material}
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className='accorddrop' style={{ background: 'transparent', boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={<Add />}
            >
              SIZE GUIDE
            </AccordionSummary>
            <AccordionDetails>
            <button onClick={() => setSeeMeasure(true)} className='seebtn'>See measurements</button>
              {sizeguide}
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className='accorddrop' style={{ background: 'transparent', boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={<Add />}
            >
              PACKAGING
            </AccordionSummary>
            <AccordionDetails>
             {packaging}
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} className='accorddrop' style={{ background: 'transparent', boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={<Add />}
            >
              SHIPPING & RETURN
            </AccordionSummary>
            <AccordionDetails>
            <div style={{ display: 'flex'}}>
                <div>
                  <p>/</p>
                  <p>/ </p>
                </div>
                <div>
                  <p>&nbsp; 3-7 business days .</p>
                  <p>&nbsp;This time is subject to change due to the nature of production and heavy order volumes.</p>
                </div>
                <p style={{position: 'absolute',top: '45%', right: '6px'}}>&nbsp; <Link to={'/faq'} style={{color: 'black', textDecoration: 'none'}}>Policy*</Link></p>
              </div>
            </AccordionDetails>
          </Accordion>
        </Drawer>
        <Drawer
          duration={250}
          hideScrollbars={true}
          onClose={closeDrawer}
          isVisible={isVisible}
        >
          <div className='modalData'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h1 style={{ fontSize: '22px' }}>Select a Size</h1>
            </div>
            <div className='variantHolder'>
              {
                variants.map((variant, index) => (
                  <button onClick={e => { addToCart(variants[index]['id']).then(() => closeDrawer()).then(() => navigate('/cart')); }}>{variant['title']}</button>
                ))
              }
            </div>
          </div>
        </Drawer>
      </div>
      <div className='PAL'>

        <PAL />
      </div>

    </div>
  )
}

export default ProductPage


/**          <h1>{product['title']}</h1>
          <p style={{ fontSize: '10px' }}>{product['description']}</p> */

/**          <div className='size__option'>
  {
    variants.map((variant)=>(
      <button>{variant['title']}</button>
    ))
  }
</div> */


/**    setSelectVariant(data['products']['nodes'][0]['variants']['nodes'][0]['id']) */

/***            {
    variants.map((variant)=>(
      <button onClick={e => {setSelectVariant(variant['id']);console.log(variant['id'])}}>{variant['title']}</button>
    ))
  } */
//*          <p>MODEL IS 177 & WEAR SIZE M, <a style={{ textDecoration: 'underline' }}>SIZE GUIDE</a></p>

/**onClick={() => addToCart()}  */


/**<div className='size__option'>
              {
                variants.map((variant) => (
                  <button onClick={e => { setSelectVariant(variant['id']); console.log(variant['id']) }}>{variant['title']}</button>
                ))
              }
            </div> */

/**            <button className='save'>SAVE</button> */